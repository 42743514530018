@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  /* word-break: break-word; */
}

/* @font-face {
  font-family: "Inter";
  src: url(./assets/font/Inter.ttf);
} */

::-webkit-scrollbar {
  height: 12px;
  width: 10px;
  background: #666666;
}

::-webkit-scrollbar-thumb {
  background: #000000;
  -webkit-box-shadow: 0px 1px 2px #000000;
}

::-webkit-scrollbar-corner {
  background: #000000;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #000000 #666666;
  transition: 0.3s;
}

body {
  margin: 0;
  font-family: Inter;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.leading-normal {
  line-height: normal !important;
}

.site-header-sticky {
  position: fixed;
  max-width: 100%;
}

.show-menu {
  display: block;
  transition: 2s;
  position: absolute;
  top: 0;
}

input:focus-visible {
  outline: none;
}

.has-child>div {
  transition: 1s;
  -webkit-transition: 1s;
  -o-transition: 1s;
}

.has-child:hover>div {
  /* display: flex; */
  visibility: visible;
  opacity: 1;
}

.h-transition {
  transition: 1s;
  -webkit-transition: 1s;
  -o-transition: 1s;
}

.h-products {
  gap: 3.5rem;
}

.h-shop .h-products {
  gap: 1rem;
}

.h-product {
  width: calc(33.333333% - 2.34rem);
  transition: 2s;
  transition: opacity 200ms;
}

.h-product:hover{
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15), 0px 4px 4px 0px rgba(0, 0, 0, 0.25); 
}

.h-shop .h-product {
  width: calc(25% - 0.75rem);
}

.h-product-shadow {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
}

.h-hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.h-hide-scrollbar::-webkit-scrollbar {
  display: none;
}

@media (max-width:767px) {
  .h-products {
    gap: 0;
  }

  .h-product {
    width: 100%;
  }
}

@media (min-width:768px) and (max-width:1023px) {
  .h-products {
    gap: 1rem;
  }

  .h-product,
  .h-shop .h-product {
    width: calc(33.333333% - 0.7rem);
  }
}

@media (min-width:1024px) and (max-width:1199px) {
  .h-products {
    gap: 1rem;
  }

  .h-product,
  .h-shop .h-product {
    width: calc(33.333333% - 0.7rem);
  }
}

@media (min-width:1200px) and (max-width:1439px) {
  .h-products {
    gap: 16px;
  }

  .h-product,
  .h-shop .h-product {
    width: calc(25% - 12px);
  }

  .h-brands .h-product {
    width: calc(33.333333% - 0.7rem);
  }
}

@media (min-width:1536px) {
  .h-products {
    gap: 1rem;
  }

  .h-product,
  .h-shop .h-product {
    width: calc(25% - 12px);
  }
}
