.accordion {
  border-bottom: 1px solid #bbb;
  text-align: left;
  outline: none;
  transition: 0.4s;

  
}

.accordion span{
  width: calc(100% - 35px);
}

.accordion .active,
.accordion:hover {
  background-color: #fff;
}

.accordion .panel {
  padding: 0 18px 18px;
  display: none;
  background-color: #D9D9D9;
  overflow: hidden;
}
