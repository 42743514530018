.mobile-product-swiper .swiper,
.footer-slider .swiper {
  width: 100%;
  height: 100%;
}

.mobile-product-swiper .swiper-container,
.footer-slider .swiper-container {
  overflow: visible;
}

.mobile-product-swiper .swiper-pagination,
.footer-slider .swiper-pagination {
  position: relative;
  margin-top: 0.62rem;
}

.mobile-product-swiper .swiper-slide,
.footer-slider .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-product-swiper .swiper-slide img,
.footer-slider .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.footer-slider .swiper-slide {
  width: 70%;
}